import React from 'react';

const Msg = ({ closeToast, toastProps }) => (
  <div>
    <h3>Generated Image</h3>
    <img style={{borderRadius: '10%'}} className="image" src={""+process.env.REACT_APP_API_URL+"/" + toastProps.output_image_path} alt="Preview" />
  </div>
);

export default Msg;
